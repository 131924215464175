
import React from 'react'
import avatarImage from  '../../../assests/testimonials/avatar.png'

const Avatar = (props) => {


  return (
    <>
     <img src={props.image!=null?props.image:avatarImage} alt="Avatar" 
     style={{
         width:"44px", height :"44px", 
         verticalAlign: 'middle',
         borderRadius:"50%" ,
         onerror:{avatarImage}
         }}/>
    </>
  )
}

export default Avatar
