
import React from 'react'
import Card from 'react-bootstrap/Card'
import parsePhoneNumber from 'libphonenumber-js'
import './NumberCard.css'


const NumberCard = ({ onNumberClick, number }) => {
    
    return (
        <Card onClick={() =>
            onNumberClick(number)}
            className="div-number-card-container">
            <div className='div-card-container-inner'>
                <div className='number-title' >{parsePhoneNumber( number.phone_number).formatInternational()}</div>
                <img src={number.country.flagUrl} alt="Image2" style={{ height: '36px', width: '36px', alignContent: 'center', alignSelf: 'center', paddingTop: 0, paddingBottom: 0 , objectFit:'contain'}} />
                <div className='div-sms-today'>Sms Today [{number.total_sms}]</div>  
            </div>
        </Card>

    )
}

export default NumberCard

