import React from 'react'
import './DashboardTopBar.css'
import smsIcon from '../../../assests/landing/topbar/vector.svg'
import canadaFlag from '../../../assests/landing/topbar/canada-flag.svg'
import timeArrow from '../../../assests/landing/topbar/time-indicator.svg'
import useScreenType from '../../../hooks/UseScreenType'


const DashBoardTopBar = (props) => {

     const {isMobile,isTablet} = useScreenType() 
     
    return (
        <div className='top-bar-dahsboard'>
            <div className='top-bar_inner'>
                <div className={!(isMobile||isTablet)?'text-wrapper-browser':'text-wrapper-mobile'}>
                    <div className='text-header'>
                        Your Temporary
                        <h1>
                            Phone Number
                        </h1>
                    </div>
                    <div className={!isMobile ? 'text-footer-browser' :'text-footer-mobile'}>
                            Contact information is not something you should share with everyone. Our service allows you to receive anonymous SMS or OTP verification without using your real number. New numbers are getting added every day. Get instant and free access to your own temporary phone number.
                    </div>
                </div>
                {!(isMobile||isTablet) &&
                    <div className='right-box-component'>
                        <div className='div-message-card-title'>
                            <div className='div-text-left'>
                                <img src={smsIcon} className="smsIcon" alt="" style={{ width: "20px", height: "20px" }} />
                                <div>SMS</div>
                            </div>
                            
                            <div className="div-number-border">
                            </div>
                            <div className="div-number">
                                <img src={canadaFlag} alt="canadaFlag1" style={{ height: 22, width: 22, alignContent: 'center', alignSelf: 'center', paddingLeft: 5 }} />
                                <div className='div-text-right'>+1(385) 732-XXXX </div>
                            </div>
                            <div className='div-card'>
                                <div className='div-card-container'>
                                    <div>
                                        <div className='div-container-whatsapp'>
                                            WhatsApp code 134-183
                                        </div>
                                        <div className='divider' />
                                        <div className='div-container-whatsapp'>
                                            Use 807 921 to verify your Instagram account. #ig
                                        </div>
                                        <div className='divider' />
                                        <div className='div-container-whatsapp'>
                                            Dear X Y, We have added a new XXXXX Live event for you. Xxxxx Yyyyyy event is scheduled for 01-04-2022 7:30 pm.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='div-time-indicator'>
                            <div >
                                <div className='div-time-title'>
                                    3 Seconds ago
                                </div>
                            </div>
                            <img src={timeArrow} alt="timeArrow" style={{ height: '71px', width: "68px", marginTop: "10px" }} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DashBoardTopBar
