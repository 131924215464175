import { connect } from 'react-redux';
import Testimonials from '../components/testimonials/Testimonials';
import { onTestimonilaSelected } from '../services/actions/testimonials-action';
import { onSlideChanged } from '../services/actions/testimonials-action';
import { getTestimonials } from '../services/actions/testimonials-action';




const mapStateToProps = state => ({
    data: state
});

const mapDispatchToProps = dispatch => ({
    onGetTestimonial: () => dispatch(getTestimonials()),
    onTestimonialsSelectedHandler: d0 => dispatch(onTestimonilaSelected(d0)),
    onSlideChangedHandler: d1 => dispatch(onSlideChanged(d1))
});

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);

