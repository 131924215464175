import { GET_ALL_FEATURES ,LOAD_FEATURES_LOADING, LOAD_FEATURES_ERROR} from '../constants/features-contants';
import ApiEndPointService from "../network/api-service";


export const getFeatures=()=> async(dispatch)=>{    
    dispatch({ type: LOAD_FEATURES_LOADING});
    try {
      const res = await ApiEndPointService.getAllFeatures();
      dispatch({
        type: GET_ALL_FEATURES,
        payload: res.data.features
      });
    } catch (err) {
        dispatch({
            type: LOAD_FEATURES_ERROR,
            payload: err,
          });

    }
}

