import "./UserOpnion.css";
import BoxComponent from "./carousal/BoxComponent";
import Carousel from "./carousal/Carousel";
import React, { useState, useEffect } from 'react'
import useScreenType from "../../../hooks/UseScreenType";

const UserOpinion = (props) => {


  const {isMobile,isTablet} = useScreenType() 


  const table = props.data.testimonials.map((element) => {
    return {
        key: element._id,
        content: (
          <BoxComponent testimonial ={element}/>
        )
      };
  });


  const [items,setItems] = useState(table);


  useEffect(() => {
    setItems(table);
  }, [props.data.testimonials]);
  
  return (
    <div className="div-user-opinion">
      {items.length>0 && <Carousel
        cards={items}
        index = {props.data.index}
        onSlideChanged = {props.onSlideChanged}
        height= {!(props.isMobile||isTablet)?"500px":"800px"}
        width="15%"
        margin="0 auto"
        offset={5}
        showArrows={false}
      />}
    </div>
  );
}

export default UserOpinion;
