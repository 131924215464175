import React from 'react'
import './Aboutus.css'
import iconAboutUs from '../../assests/aboutus/icon_aboutus.svg'
import useScreenType from '../../hooks/UseScreenType'

const Aboutus = () => {

  const {isMobile,isTablet,isLaptop} = useScreenType() 

    return (
        <div className='div-aboutus'>
            <div className='div-aboutus-inner'>
                <div className={!(isMobile || isTablet||isLaptop )?'div-aboutus-left-browser':'div-aboutus-left-mobile'}>
                    <div className='title-top'>
                        About Us
                    </div>
                    <div className='title-middle'>
                        <img src={iconAboutUs} alt="about-us" style={{ height: '50px', width: '50px', alignContent: 'center', alignSelf: 'center', paddingTop: 0, paddingBottom: 0, objectFit: 'contain' }} />
                        <div className='title-text-middle'>
                            About us
                        </div>

                    </div>
                    <div className='divider-container'>
                        <div className='div-line' />
                    </div>
                    <div className={!(isMobile || isTablet||isLaptop)?'aboutus-desc-browser':'aboutus-desc-mobile'}>
                        Our temporary phone numbers receive anonymous SMS online from anywhere in the world. The best part about our service is it's completely free and always will be.
                        Anyone can use our free temporary phone numbers to receive SMS for different purposes including OTP verification, software testing, etc.
                        Our main objective is to protect users’ privacy free of cost. Our numbers will assist them to hide their real identity from the public eyes.
                        Especially when users don’t want to share their personal phone numbers on social media, apps, or web services, they can verify or activate their accounts easily using our free phone numbers.
                        We add new phone numbers every day giving users more options to choose from. On top of that, we make sure our users don’t have to wait too long for receiving their SMS. Users can easily refresh the message list to view the  recently received message.
                    </div>
                </div>
                {!(isMobile||isTablet||isLaptop)&&<div className='div-aboutus-right'>
                    <div className='div-aboutus-card'>
                            <div className='div-rectangle-white' />
                            <div className='div-aboutus-right-container' />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Aboutus
