/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import NumberCard from "../number_card/NumberCard";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NumberShimmer from "../shimmer/NumberShimmer";
import "./NumberList.css";
import { channel } from "../../../../../eventbus/Pusher";

const NumberListView = (props) => {
  let navigate = useNavigate();

  const listToChannel = () => {
    channel.bind("event-received", function (data) {
      props.onNewSmsReceived(
        JSON.parse(localStorage.getItem("selected_country")).alpha_two_code
      );
    });
  };

  useEffect(() => {
    props.onGetNumbers(
      (props.data.countryListReducer &&
        props.data.countryListReducer.country &&
        props.data.countryListReducer.country.alpha_two_code) ||
        "CA"
    );
  }, [
    props.data.countryListReducer &&
      props.data.countryListReducer.country &&
      props.data.countryListReducer.country.alpha_two_code,
  ]);

  useEffect(() => {
    listToChannel();
    return () => {
      channel.unbind("event-received");
    };
  }, [props.data.numberListReducer && props.data.numberListReducer.onRefresh]);

  const onCardSelected = (value) => {
    navigate(`/messgeList/${value.phone_number}`, {
      replace: false,
      state: { number: value },
    });
  };

  const isNumbersLoading = props?.data.numberListReducer.isLoading;
  const numbers = props?.data.numberListReducer.numbers?.filter(
    (number) => number.phone_number !== "+447862128622"
  );

  return (
    <div className="div-numberlist">
      {isNumbersLoading && (
        <div>
          <Row md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
            {[...Array(8)].map((value, k) => (
              <Col key={k} xs={12} md={4} lg={3}>
                <NumberShimmer />
              </Col>
            ))}
          </Row>
        </div>
      )}
      {(numbers === undefined || numbers.length === 0) &&
        !props.data.numberListReducer.isLoading && <p>{}</p>}
      {props.data.numberListReducer.error &&
        !props.data.numberListReducer.isLoading && (
          <p>{props.data.numberListReducer.error}</p>
        )}
      <Row md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
        {numbers.map((value, k) => (
          <Col key={k} xs={12} md={4} lg={3}>
            <NumberCard
              key={value}
              number={numbers[k]}
              onNumberClick={onCardSelected}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NumberListView;
