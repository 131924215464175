import React, { useEffect } from 'react'
import ComponentHeader from '../common/componentheader/ComponentHeader'
import ComponentTitle from '../common/componenttitle/ComponentTitle'
import './Testimonials.css'
import UserList from './userlist/UserList'
import UserOpinion from './opinion/UserOpnion'
import useScreenType from '../../hooks/UseScreenType'



const Testimonials = (props) => {

  const { index, slideIndex, testimonials } = props.data.testimonialsReducer;


  const { isMobile, isTablet } = useScreenType()


  const onuserSelected = (index) => {
    props.onTestimonialsSelectedHandler(index)
  }

  const onSlideChanged = (index) => {
    props.onSlideChangedHandler(index);
  }

  useEffect(() => {
    props.onGetTestimonial()
  }, []);


  return (
    <>
      {testimonials.length>0 && <div className='div-testimonials'>
        <ComponentTitle tilte={'Testimonial'} />
        <ComponentHeader subtitle={"What Users Saying"} />
        <UserOpinion data={{ testimonials: testimonials, index: index }} onSlideChanged={onSlideChanged} isMobile={isMobile} />
        <UserList testimonials={testimonials} onSelected={onuserSelected} slideIndex={slideIndex != null ? slideIndex : 0} isMobile={isMobile} />
      </div>}
    </>

  )
}

export default Testimonials
