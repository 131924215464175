import React from 'react'
import './Dashboard.css'
import Banner  from '../banner/Banner'
import NumberListing from './numberlisting/NumberListing';
import AccordionContainer from '../../containers/faqs-container';
import eventBus from '../../eventbus/EventBus';
import { useEffect } from 'react';
import { scroller } from "react-scroll";
import Aboutus from '../aboutus/Aboutus';
import TestimonialsContainer from '../../containers/testimonials-container'
import Features from '../features/Features';
import Porcess from '../process/Porcess';




const Dashboard = () => {

 const  scrollToSection = () => {
    scroller.scrollTo("div-accordain", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    eventBus.on("onFaqClicked", (data) =>
    scrollToSection()
    )
    return () => {
      window.scrollTo(0, 0)
      eventBus.remove("onFaqClicked")
    }
  }, [])

  return (
    <>
      <div className='dashboard-container'>
      <NumberListing/>
       <Porcess/>
       <Features/> 
       <Aboutus/> 
       <TestimonialsContainer/>
       <div className='div-accordain'>
       <AccordionContainer/> 
       </div>
      </div> 
     <Banner/> 
    </>
  )
}

export default Dashboard
