import {combineReducers}  from 'redux';
import dasboardReducer from '../reducers/dashboard-reducer';
import numberListReducer from '../reducers/numberlist-reducer';
import countryListReducer from '../reducers/country-reducer';
import smsReducer from '../reducers/smslist-reducer'
import faqsReducer from '../reducers/faq-reducer'
import testimonialsReducer from '../reducers/testimonials-reducer';
import featuresReducer from '../reducers/features-reducer';




export default combineReducers({
    dasboardReducer,
    numberListReducer,
    countryListReducer,
    smsReducer,
    faqsReducer,
    testimonialsReducer,
    featuresReducer
});

