import React, { useEffect } from 'react'
import Footer from '../../footer/Footer'
import Header from '../../header/Header'
import './PrivacyPolicy.css'
import PrivacyContactTopBar from '../topbar/PrivacyContactTopBar'
import { Link } from 'react-router-dom'
import { PRIVACY_TITLE, PRIVACY_DESC } from '../constants'

const PrivacyPolicy = () => {


  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <div>
      <Header showFaq={false} showPrivacy ={false} isContactSelected={false} isHomeSelected={false}/>
      <PrivacyContactTopBar title={PRIVACY_TITLE} desc={PRIVACY_DESC} />
     <div style={{backgroundColor:'#f6f8fb',textAlign: 'center'}}>
     <div className='privacy-container'>
        <div className='container-home-privacy'>
          <div className='privacy-policy-home'>
            <Link to={"/"} style={{
              padding: "0px 0px",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              color: "#6E6681",
            }}> Home</Link>
            <div style={{margin:"0px 3px"}}>
              &#62;
            </div>
          </div>
          <div className='privacy-policy'>Privacy Policy</div>
        </div>
        <div className='container-desc'>
        <div className='container-date'>
          April 30, 2021
        </div>
        <div className='privacy-text-privacy'>
          Before using, please be aware that the following number is a shared number. This means anyone can view the content of the message.
          It is not recommended to use this phone number to receive important information or phone verification, such as Facebook, PayPal, Google, etc.
          This phone number can be used by others to retrieve your password, so keep an eye on your personal information when you register.ÒÒ
        </div>
        <div className='privacy-text-title'>
          Consent
        </div>
        <div className='privacy-text-privacy'>
          Using this site implies that you accept responsibility for your actions.
          <br />
          You agree to take full responsibility for any harm or damage you suffer as a result of the use, or non-use, of the information available on this website or the resources available for download from this website.
          <br />
          You agree to use judgment and conduct due diligence before taking any financial decision.
          <br />
          Please contact us if you have any questions or if you wish to receive more information about our Privacy Policy.
        </div>
        <div className='privacy-text-title'>
          General Data Protection Regulation (GDPR)
        </div>
        <div className='privacy-text-privacy'>
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </div>
        <div className='privacy-text-subtitle'>
          The right to access
        </div>
        <div className='privacy-text-privacy'>
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </div>
        <div className='privacy-text-subtitle'>
          The right to erasure
        </div>
        <div className='privacy-text-privacy'>
          You have the right to request that we erase your personal data, under certain conditions.
        </div>
        <div className='privacy-text-subtitle'>
          The right to restrict processing
        </div>
        <div className='privacy-text-privacy'>
          You have the right to request that we restrict the processing of your personal data, under certain conditions.
        </div>
        <div className='privacy-text-subtitle'>
          The right to rectification
        </div>
        <div className='privacy-text-privacy'>
          You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
        </div>
        <div className='privacy-text-subtitle'>
          The right to object to processing
        </div>
        <div className='privacy-text-privacy'>
          You have the right to object to our processing of your personal data, under certain conditions.
        </div>
        <div className='privacy-text-subtitle'>
          The right to data portability
        </div>
        <div className='privacy-text-privacy'>
          You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          <br />
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        </div>
        <div className='privacy-text-title'>
          Log files
        </div>
        <div className='privacy-text-privacy'>
          Most browsers collect certain information, such as your IP address, device type, screen resolution, operating system version, and internet browser type and version. This information is gathered automatically and stored in log files.
        </div>
        <div className='privacy-text-title'>
          Cookie Policy
        </div>
        <div className='privacy-text-privacy'>
          This website uses its own cookies and third parties to provide visitors with a better navigation experience and services tailored to the needs and interests of each. These cookies may come from the following third parties: Google Analytics, Google Adsense, etc.
          <br />
          You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
          <br />
          Cookies play an important role in facilitating the access and delivery of multiple services the user enjoys on the Internet, such as:
        </div>
        <div className='privacy-text-privacy'>
          <ul>
            <li style={{ listStyle: 'circle' }}>Customize settings such as the language in which the site is viewed.</li>
            <li style={{ listStyle: 'circle' }}>Cookies provide site owners with valuable feedback on how their sites are used by users so they can make them more effective and more user-friendly.</li>
            <li style={{ listStyle: 'circle' }}>Allow multimedia or other applications from other sites to be included in a particular site to create a more valuable, useful, and enjoyable navigation experience.</li>
            <li style={{ listStyle: 'circle' }}>Improve the efficiency of online advertising.</li>
          </ul>
        </div>
        <div className='privacy-text-privacy'>
          These ads may use cookies to provide a customized ad experience for each visitor.
          <br />
          Some of our advertising partners may use cookies and web beacons on our site. Our advertising partners include ....
          <br />
          <> </>1.  Google Adsense,Google Analytics
          <br />
          <>  </>2.  Google Analytics
        </div>
        <div className='privacy-text-title'>
          Google DoubleClick DART Cookie
        </div>
        <div className='privacy-text-privacy'>
          Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <Link to='' onClick={() => {
            openInNewTab('https://policies.google.com/technologies/ads')
          }}>URL – https://policies.google.com/technologies/ads</Link>
        </div>
        <div className='privacy-text-title'>
          Advertising Partners Privacy Policies
        </div>
        <div className='privacy-text-privacy'>
          Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on GetfreeSMSonline, which are sent directly to users' browser.
          <br />
          They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
          <br />
          Note that GetfreeSMSonline has no access to or control over these cookies that are used by third-party advertisers.
        </div>
        <div className='privacy-text-title'>
          Links to other sites
        </div>
        <div className='privacy-text-privacy'>
          This Service may contain links to other sites. You will be redirected to the that site if you click on any third-party link. Note that these sites are not administered by us. Therefore, we strongly suggest researching the privacy policies of these websites. Third-party sites and services are outside of our control and we assume no responsibility for their content, privacy policies, or practices.
        </div>
        <div className='privacy-text-title'>
          Children’s privacy
        </div>
        <div className='privacy-text-privacy'>
          No Personal Information from our website about children under the age of 13 is collected, maintained, or used by us knowingly. Users under the age of 13 are requested not to use our site without the supervision of a responsible adult.
        </div>
        <div className='privacy-text-title'>
          CHANGES TO THIS POLICY
        </div>
        <div className='privacy-text-privacy'>
          Whenever we deem necessary, we may amend this Policy. In the event that we make material changes to how we collect, use, disclose, or otherwise process personal information, we may not post a notice regarding the change on the services.
        </div>
        </div>
        <div style={{ paddingBottom: '120px' }} />

      </div>
     </div>
      <Footer />
    </div>
  )
}
export default PrivacyPolicy
