
import React from 'react'
import './CountryList.css'
import { useEffect } from 'react'
import CountryCard from './CountryCard'
import Shimmer from "react-shimmer-effect";
import useScreenType from '../../../../hooks/UseScreenType'
import { number } from 'yup';



const CountryList = (props) => {

  const shimmerlist = ['', '', ''];
  const countries = props.data.countryListReducer.countries;

  const onCountrySelected = (value) => {
    localStorage.setItem('selected_country',JSON.stringify(value));
    props.onCountrySelectedHandler({ ...value });
  }

  const { isMobile, isTablet } = useScreenType()

  useEffect(() => {
    props.onGetCountyList()
  }, []);

  return (
    <div>
      <div className={!(isMobile || isTablet) ? 'div-container-browser' : 'div-container-mobile'}>
        <div className='div-text'>
          Choose Country:
        </div>
        <div className='div_country'>
          {props.data.countryListReducer.isLoading && shimmerlist.map((countryList, k) => (
            <Shimmer key={k}>
              <div style={{ minHeight: '44px', minWidth: '96px', marginRight: '16px', borderRadius: '8px' }} />
            </Shimmer>
          ))
          }
          {countries.map((countryList, k) => (
            <CountryCard key={countries[k].id} onCountrySelected={onCountrySelected} country={countries[k]} selected={(props.data && props.data.countryListReducer && props.data.countryListReducer.country && props.data.countryListReducer.country.alpha_two_code === countries[k].alpha_two_code) ? true : false} />
          ))}
        </div>

      </div>
    </div>
  )
}

export default CountryList
