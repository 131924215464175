import React,{useEffect} from 'react'
import './LeftBoxComponent.css'
import { useDispatch} from 'react-redux';
import {getCountryDetails} from '../../../../services/actions/country-action'
import parsePhoneNumber from 'libphonenumber-js'
import useScreenType from '../../../../hooks/UseScreenType';


const LeftBoxComponent = (props) => {

    const dispatch = useDispatch();

    const {isMobile,isTablet} = useScreenType() 

    const country  = props.data.countryListReducer.country;
  
    useEffect(() => {
        dispatch(getCountryDetails(parsePhoneNumber(props.number).country))
      }, [])

    return (
        <div className={!(isMobile||isTablet)?'left-box-component':'left-box-component1'}>
            <div className="main-text">
            <div className='text-header'>Receive SMS from  {country&& country.name}</div>
            <div className='text-footer'>
                 Verifying and activating your account just got easy! We provide instant access to disposable and temporary numbers from Canada. Use these numbers for receiving SMS or OTP verification without any cost. Annual Plan 10% OFF for Permanent Phone Number. Looking for permanent numbers after successfully testing with disposable numbers? We've got you covered. Find the cheapest virtual phone number from countries across the world. Get you number now!              
                 </div>
            </div>
        </div>
    )
}

export default LeftBoxComponent
