import React, { useState, useRef, useEffect } from 'react'
import './UserList.css'
import UserTile from './usertile/UserTile'
import Slider from "react-slick";
import useScreenType from '../../../hooks/UseScreenType';



const UserList = (props) => {

  const {isMobile,isTablet,isLaptop} = useScreenType() 

  const sliderRef = useRef();
  
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: (isMobile || isTablet || isLaptop)?true:false,
    centerPadding: "0px",
    slidesToShow: 3,
    loop:true, 
    speed: 500,
    autoplay: false,
    touchMove: true,
    swipeToSlide: true,
    initialSlide: 2,
    slidesToShow: (isMobile||isTablet||isLaptop)?1:(props.testimonials.length <3?1:(props.testimonials.length<=4?3:5)),
    useCSS: true,
    useTransform: true,
    afterChange: function (index) {
      props.onSelected(index);
      setIndex(index);
    }
  };

  const [index, setIndex] = useState(props.slideIndex);

  const onSelected = (index, testimonial) => {
    sliderRef.current.slickGoTo(index);
    props.onSelected(index);
  }


  useEffect(() => {
    setIndex(props.slideIndex);
    sliderRef.current.slickGoTo(props.slideIndex);
  }, [props.slideIndex]);

  return (
    <div className='div-user-list'>
      <Slider {...settings} ref={sliderRef}>
        {props.testimonials.map((values, k) => (
          <UserTile key={k} testimonial={props.testimonials[k]} index={k} onSelected={onSelected} selected={k === index ? true : false} />
        ))}
      </Slider>
    </div>
  )
}

export default UserList
