import './EvenBoxComponent.css'
import React, { useState} from 'react'
import iconExpand  from '../../../assests/accordion/icon_expand.svg'
import iconCollapsed  from '../../../assests/accordion/icon_collapsed.svg'
const EvenBoxComponent = (props) => {


    const [selected, setSelected] = useState(null)
  
  
    const toggle = i => {
      if (selected === i) {
        return setSelected(null)
      }
      setSelected(i)
    }
  

    return (
        <div>
            {props.data.map((item, i) => (
                <div className="item" key={i}>
                    <div className="title-untoggle" onClick={() => toggle(i)}>
                        <div className='item-accordain-title'> {item.question}</div>
                        <span>{selected === i ? <img src={iconCollapsed} alt="collpase"  className='accordain-icon'/> : <img src={iconExpand} alt='expand' className='accordain-icon'/>
                        }</span>
                    </div>
                    <div className={selected === i ? 'content show' : 'content'}>{item.answer}</div>
                </div>
            ))}
        </div>
    )
}

export default EvenBoxComponent
