import {ON_NUMBER_ITEM_TAP , GET_ALL_NUMBERS ,LOAD_NUMBER_LOADING, LOAD_NUMBER_ERROR,REFRESH_ALL_NUMBERS} from '../constants/number-constants';
import ApiEndPointService from "../network/api-service";


export const onCardSelected =(number)=>{
    return {
        type: ON_NUMBER_ITEM_TAP,     
        number : number,
    };

}


export const getNumbers=(country)=> async(dispatch)=>{    

    dispatch({ type: LOAD_NUMBER_LOADING});

    try {
      const res = await ApiEndPointService.getAllNumbers(country);
      dispatch({
        type: GET_ALL_NUMBERS,
        payload: res.data.numbers,
      });
    } catch (err) {
        console.log(err);
        dispatch({
            type: LOAD_NUMBER_ERROR,
            payload: err,
          });

    }

}

export const refetchNumbers=(country) => async(dispatch)=>{
   console.log("fetch numbers");
  try {
    const res = await ApiEndPointService.getAllNumbers(country);
    if(res.data.numbers !== undefined){
      dispatch({
        type: REFRESH_ALL_NUMBERS,
        payload: res.data.numbers,
      });
    }
  } catch (err) {
      dispatch({
          type: LOAD_NUMBER_ERROR,
          payload: [],
        });

  }

}
