
import React from 'react'
import '../SmsList.css'
import  emptyMsg from '../../../../assests/receivesms/empty_sms.svg'
import Table from 'react-bootstrap/Table'
import RefreshButton from '../../../banner/RefreshButton';
import useScreenType from '../../../../hooks/UseScreenType';


const EmptySms = ({onRefreshClicked}) => {

  const {isMobile,isTablet} = useScreenType() 


  return (
    
      <div className={!(isMobile||isTablet)?'container_tabel':'container_tabel1'}>
          <div className={!(isMobile||isTablet)?'container-title':'container-title1'}>
          <div className='container-title-text-start'>Received SMS: {0}</div>
          <div className='container-title-text-middle'>{}</div>
          <div className='container-button'><RefreshButton onClicked={onRefreshClicked}/></div>
        </div>
        <div className={!(isMobile||isTablet)?'div-title-empty-messsage':'div-title-empty-messsage1'}>
          <Table striped hover size="sm" style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th><div className='container-text'>TimeStamp</div></th>
                <th><div className='container-text'>Number</div></th>
                <th><div className='container-text'>Message</div></th>
              </tr>
            </thead>
            </Table>
        </div>
        <div className='container-image'>
                    <img src= {emptyMsg} alt='' className="img-empty"/>
                    <div className='empty-msg'>Not Received SMS yet</div>
            </div>
    </div>
  )
}

export default EmptySms
