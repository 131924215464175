import { connect } from 'react-redux';
import NumberList from '../components/dashboard/numberlisting/number/numberlist/NumberList';
import { onCardSelected, getNumbers,refetchNumbers} from '../services/actions/numberlist-actions';


const mapStateToProps = state => ({
    data: state
});


const mapDispatchToProps = dispatch => ({

    onGetNumbers: (alpha_two_code) => dispatch(getNumbers(alpha_two_code)),
    onNewSmsReceived: (alpha_two_code) => dispatch(refetchNumbers(alpha_two_code)),
    onCardSelectedHandler: data => dispatch(onCardSelected(data))
    
});

export default connect(mapStateToProps, mapDispatchToProps)(NumberList);

