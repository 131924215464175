import { GET_TESTIMONIALS, ON_TESTIMONIAL_ITEM_TAP,ON_TESTIMONIAL_SLIDE_CHANGED, LOAD_TESTIMONIAL_ERROR } from '../constants/testimonials-constant';


const initialState = {
    testimonials: [],
    isLoading: true,
    error: null,
    index: 0
}

export default function onCountryReducerLoad(state = initialState, action) {
    switch (action.type) {
        case GET_TESTIMONIALS:
            return {
                ...state,
                testimonials: action.payload.testimonials,
                index: 0
            };
        case ON_TESTIMONIAL_ITEM_TAP:
            return {
                ...state,
                index: action.payload.index
            };

        case ON_TESTIMONIAL_SLIDE_CHANGED:
            return {
                ...state,
                slideIndex: action.payload.slideIndex
            };
        case LOAD_TESTIMONIAL_ERROR:
            return {
                ...state,
                isLoading: false,
                testimonials: [],
                error: action.payload
            }
        default:
            return state;
    }

}




