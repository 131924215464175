import {GET_ALL_FAQS, LOAD_FAQ_LOADING, LOAD_FAQ_ERROR } from '../constants/faq-constants';


const initialState = {
    faqs: null,
    isLoading: true,
    error: null,
}

export default function onFaqsFetched(state = initialState, action) {
    switch (action.type) {
        case LOAD_FAQ_LOADING:
            return {
                ...state,
                isLoading: true,
                faqs: null

            }
        case GET_ALL_FAQS:
            return {
                ...state,
                isLoading: false,
                faqs: action.payload
            }

        case LOAD_FAQ_ERROR:
            return {
                ...state,
                isLoading: false,
                faqs: null,
                error: action.payload
            }
        default:
            return state;
    }

}