import './Home.css';
import React from 'react'
import Footer from './footer/Footer'
import Header from './header/Header'
import DashboardContainer from '../containers/dashboard-container';
import  {Helmet}  from 'react-helmet'




const Home = () => {

  const articleStructuredData1 = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "GetFreeSMSOnline",
    "image": "https://getfreesmsonline.com/static/media/logo.47924b67.png",
    "description": "Receive Free Text Messages (SMS) online across the globe without a phone number including the USA, UK, and Canada. Get Verification Code (OTP) for Facebook, Instagram, Google, Telegram & many more…",
    "brand": {
      "@type": "Brand",
      "name": "GetFreeSMSOnline"
    },
    "sku": "GetFreeSMSOnline",
    "offers": {
      "@type": "Offer",
      "url": "",
      "priceCurrency": "USD",
      "price": "0"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "5",
      "ratingCount": "1034"
    }
  }


  const articleStructuredData2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": ":moneybag: How much does this service cost?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Using our service is free and there is no charge associated with it. From the provided list, simply choose a free number to receive SMS without any risk."
      }
    },{
      "@type": "Question",
      "name": ":eyes: Can anyone see the contents of the messages?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. Everyone has access to all messages that we receive. Our system does not block any messages."
      }
    },{
      "@type": "Question",
      "name": ":hourglass: How long are the SMS (messages kept)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "After messages are received, they are retained for about 7 days."
      }
    },{
      "@type": "Question",
      "name": ":speech_balloon: Do you send SMS (messages)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No. We don't send out messages. Each of our telephone numbers only provides inbound service."
      }
    },{
      "@type": "Question",
      "name": ":arrow_down_small: Do I need to download any software or mobile app to receive the SMS?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You don't have to download anything. This is a completely web-based service. You can receive SMS online through any device (laptop, desktop, or mobile) at any time from anywhere with an internet connection."
      }
    },{
      "@type": "Question",
      "name": ":no_mobile_phones: Do you block specific messages?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No, our SMS service doesn’t block any received SMS. All the messages are visible to everyone."
      }
    },{
      "@type": "Question",
      "name": ":bust_in_silhouette: Do I have to register an account to receive an SMS?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely not. You don’t need to register for an account to receive an SMS. You can straightaway use any temporary phone number and receive your SMS."
      }
    },{
      "@type": "Question",
      "name": ":date: I need a number from the previous month, can you provide it?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No. We have temporary numbers; therefore, it is possible that another person owns the same one in the future. You can buy specific permanent numbers from here."
      }
    },{
      "@type": "Question",
      "name": ":flag-england: What countries are currently available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Currently, users can access phone numbers from the USA, UK, and Canada."
      }
    },{
      "@type": "Question",
      "name": "What is a fake phone number?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A fake phone number is a real temporary number. It is called fake because it is not your phone number but you are using it as your own to receive SMS. It's a great way to avoid using your personal phone number for privacy."
      }
    },{
      "@type": "Question",
      "name": "Can I receive free OTP for Disney Plus Hotstar Premium?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can receive free OTP for Disney Plus Hotstar Premium from anytime, any device globally."
      }
    },{
      "@type": "Question",
      "name": "Is it necessary to have a phone number to receive SMS?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You don’t need to have your personal phone number to receive SMS. You can use our temporary phone number for receiving SMS, OTPs online just visiting getfreesmsonline.com ."
      }
    },{
      "@type": "Question",
      "name": ":earth_americas: Can I receive SMS internationally?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can receive SMS internationally from any device in any country without any hassle."
      }
    },{
      "@type": "Question",
      "name": ":keycap_ten: What is a 10-minute phone number?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A 10-minute phone number is a term used for a temporary phone number. This type of phone number will only remain active for 10 minutes. But the temporary phone numbers in our list are active for several days or months."
      }
    }]
  }

  return (
    <div className='.Home'>
      {<Header showFaq={false} showPrivacy={false} isContactSelected={false} isHomeSelected={true} />}
      <DashboardContainer />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData1)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData2)}
        </script>
      </Helmet>
      <Footer/>
    </div>
  )
}

export default Home
