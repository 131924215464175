import {GET_TESTIMONIALS, ON_TESTIMONIAL_ITEM_TAP,ON_TESTIMONIAL_SLIDE_CHANGED, LOAD_TESTIMONIAL_ERROR, TESTIMONIALS_LOADING} from '../constants/testimonials-constant';
import ApiEndPointService from "../network/api-service";







export const onTestimonilaSelected = (index)=>{


    return {
        type: ON_TESTIMONIAL_ITEM_TAP,     
        payload : {
            testimonials:[],
            index:index
        },
    };
}


export const onSlideChanged = (index)=>{

    return {
        type: ON_TESTIMONIAL_SLIDE_CHANGED,     
        payload : {
            slideIndex:index
        },
    };
}

export const getTestimonials=()=> async(dispatch)=>{    

    dispatch({ type: TESTIMONIALS_LOADING});
    try {
      const res = await ApiEndPointService.getTestimonials();

      dispatch({
        type: GET_TESTIMONIALS,
        payload: {
            testimonials: res.data.testimonials,
            index:0,
            slideIndex:0,
        },
      });
    } catch (err) {
        dispatch({
            type: LOAD_TESTIMONIAL_ERROR,
            payload: err,
          });

    }
}

