import React from 'react'
import './ReceiveSmsTopbar.css'
import RightBoxComponent from './rightbox/RightBoxComponent'
import LeftBoxContainer from '../../../containers/leftbox-container'


const ReceiveSmsTopBar = (props) => {
  
  return (
    <div className='top-bar-receivesms'>
      <div className="box-container">
        <LeftBoxContainer number ={props.number} />
        {props.isDesktopView && <RightBoxComponent number={props.number}/>}
      </div>
    </div>
  )
}

export default ReceiveSmsTopBar



