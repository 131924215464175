import { connect } from 'react-redux';
import FlagComponent from '../components/common/FlagComponent'
import { onCountrySelected } from '../services/actions/country-action';
import { getCountryDetails } from '../services/actions/country-action';
import parsePhoneNumber from 'libphonenumber-js'




const mapStateToProps = state => ({
    data: state
});

const mapDispatchToProps = dispatch => ({
    onGetCountryDetails: (number) => dispatch(getCountryDetails(parsePhoneNumber(number).country)),
    onCountrySelectedHandler: data => dispatch(onCountrySelected(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FlagComponent);

