import {connect}  from 'react-redux';
import  NumberList from '../components/accordion/Accordion';
import { getFaqs } from '../services/actions/faq-action'

const mapStateToProps = state =>({
    data : state
});

const mapDispatchToProps = dispatch => ({
    onGetFaqs:()=>dispatch(getFaqs())
});

export default connect(mapStateToProps,mapDispatchToProps)(NumberList);

