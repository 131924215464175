
import React from 'react'
import './ComponentDescription.css'

const ComponentDescription = (props) => {
  return (
    <div className='div-component-description'>
      {props.description}
    </div>
  )
}

export default ComponentDescription
