import { ON_SUBMIT_QUERY, ON_CONTACTUS_SUCCESSFUL, ON_CONTACTUS_ERROR } from '../constants/faq-constants';
import ApiEndPointService from "../network/api-service";


export const submitQuery = (value) => async (dispatch) => {
    dispatch({ type: ON_SUBMIT_QUERY });
    try {
        const res = await ApiEndPointService.postQuery(value);
        
        dispatch({
            type: ON_CONTACTUS_SUCCESSFUL,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {

        dispatch({
            type: ON_CONTACTUS_ERROR,
            payload: err,
        });
        return Promise.reject(err);
    }
}
