
import { GET_All_SMS_BY_NUMBERS, LOAD_SMS_LOADING, LOAD_SMS_ERROR,REFRESH_SMS_BY_NUMBERS} from '../constants/smslist-contants';
import { timeAgo } from '../../../src/utils/utils';
import parsePhoneNumber from 'libphonenumber-js'



const initialState = {
    data: [],
    isLoading: true,
    error: null,
}

export default function onSmsLoaded(state = initialState, action) {


    switch (action.type) {
        case LOAD_SMS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: []                                            

            }
        case GET_All_SMS_BY_NUMBERS:
            var data = [];
            action.payload.forEach(element => {
                data.push({
                    timestamp: timeAgo(element.created_at).concat('  ').concat(`${element.created_at.split('T')[0]}`),
                    number: parsePhoneNumber(element.to[0].phone_number).formatInternational().slice(parsePhoneNumber(element.to[0].phone_number).formatInternational(), -4) + '****',
                    text: element.message
                })
            });
            return {
                ...state,
                isLoading: false,
                data: data
            }

        case REFRESH_SMS_BY_NUMBERS:
                var data = [];
                action.payload.forEach(element => {
                    data.push({
                        timestamp: timeAgo(element.created_at).concat('  ').concat(`${element.created_at.split('T')[0]}`),
                        number: parsePhoneNumber(element.to[0].phone_number).formatInternational().slice(parsePhoneNumber(element.to[0].phone_number).formatInternational(), -4) + '****',
                        text: element.message
                    })
                });
            return {
                ...state,
                isLoading: false,
                data: data
            }

        case LOAD_SMS_ERROR:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.payload
            }
        default:
            return state;

    }



}


