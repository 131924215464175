import {connect}  from 'react-redux';
import ContactUsForm from '../components/privacycontact/contact/contactusform/ContactUsForm';
import {submitQuery}  from '../services/actions/contactus-action';


const mapStateToProps = state =>({
    data : state
});

const mapDispatchToProps = dispatch => ({
   onSubmitQueryHandler:data => dispatch(submitQuery(data))
});


export default connect(mapStateToProps,mapDispatchToProps)(ContactUsForm);

