import React, {useEffect } from 'react'
import './accordion.css'
import AccordinShimmer  from './shimmer/AccordinShimmer'
import OddBoxComponent from './oddboxcomponent/OddBoxComponent';
import EvenBoxComponent from './evenboxcomponent/EvenBoxComponent';
import useScreenType from '../../hooks/UseScreenType';
import MobileAccordainComponent from './mobilecomponent/MobileAccordainComponent';


function Accordion(props) {


  const { isMobile, isTablet } = useScreenType()


  useEffect(() => {
    props.onGetFaqs()
  }, [])

  const data = props.data.faqsReducer.faqs;

  return (
    <>
    {props.data.faqsReducer.isLoading && <div><AccordinShimmer/></div>}
    {props.data.faqsReducer.faqs && !props.data.faqsReducer.isLoading && <p>{}</p>}
    {props.data.faqsReducer.error && !props.data.faqsReducer.isLoading && <p>{}</p>}
    {props.data.faqsReducer.faqs &&   
    <div className='accordion-container'>
      <div className="head">
       Frequently Asked Questions
      </div>
      {!(isMobile || isTablet)?<div className="accordion">
        <OddBoxComponent data ={data.filter((element, index) => index%2 === 0)}/>
        <EvenBoxComponent data = {data.filter((element, index) => index%2 !== 0)}/>
      </div>:<MobileAccordainComponent data ={ data}/>}
    </div> }
    </>
  )
}

export default Accordion