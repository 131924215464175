import React  from 'react'
import Banner from '../banner/Banner'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import './ReceiveSms.css'
import ReceiveSmsTopbar from './topbar/ReceiveSmsTopBar'
import SmsListContainer from '../../containers/smslist-containers'
import {useParams} from 'react-router-dom';
import useScreenType from '../../hooks/UseScreenType'


const ReceiveSms = () => {


  const { number } = useParams();

  const {isMobile} = useScreenType() 

  return (
    <div className='.sms-container'>
     <Header showFaq={false} showPrivacy ={false} isContactSelected={false} isHomeSelected={false}/>
      <ReceiveSmsTopbar number={number} isDesktopView={isMobile?false:true}/>
      <SmsListContainer number={number} />
      <Banner />
      <Footer />
    </div>
  )
}

export default ReceiveSms
