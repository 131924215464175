import React from 'react'
import { Card } from 'react-bootstrap';
import './CountryCard.css'

const CountryCard = (props) => {

  return (
    <Card onClick={() => props.onCountrySelected(props.country)}
      style={{ minWidth:'96px',minHeight:'44px', borderWidth: props.selected?1:1, border: props.selected?'1px solid #2D66F7':'1px solid #E6E8EC' ,borderRadius:'8px', textAlign: 'center',justifyContent:'center', marginRight: '16px' , backgroundColor:props.selected?'#F3F7FE':'#FFFFFF', cursor: 'pointer',padding:'8px 24px'}}>
      <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', flexFlow: true , gap:'10px'}}>
        <img  src ={props.country.flagUrl}  alt="Image3" style={{ height: 16, width: 16, alignContent: 'center', alignSelf: 'center',objectFit:'contain'}} />
        <div  className='div-country-name'>{props.country.name}</div>
      </div>
    </Card>
  )
}

export default CountryCard
