import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assests/union.svg'
import './MobileFooter.css'


const MobileFooter = () => {
  return (
    
    <footer>
      <div className='container-footer-mobile'>
          <div className='logo-name'>
            <img src={logo} alt='logo' className='footer-logo' />
            <div className='div-title-top'>
              GetFreeSMSonline
            </div>
          </div>
        <div className='footer-bottom'>
          <Link className="footer-policy" to={"/privacy"} >Privacy Policy </Link> | <Link className="footer-contactus" to={"/contactus"}> Contact Us</Link>
        </div>
        <div className='copyright-mobile'>
            All Rights Reserved, 2022
          </div>
      </div>
    </footer>)

}

export default MobileFooter
