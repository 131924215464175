import React from 'react'
import './RightBoxComponent.css'
import msgIcon from './../../../../assests/receivesms/icon_message.svg'
import innerCircle from './../../../../assests/receivesms/inner_circle.svg'
import FlagComponentContainer from'../../../../containers/flag-container'

function RightBoxComponent(props) {


  return (
    <div className='rightBoxComponent'>
      <div className="outerBorderCircle">
        <img src={innerCircle} className='innerCircle' alt="" />
        <div className="canadaFlag" alt="" >
         <FlagComponentContainer height ={'20px'} width={'30px'} boxFit={'contain'} number={props.number}/>
          </div>
        <img src={msgIcon} className="msgIcon" alt="" />
      </div>
    </div>
  )
}

export default RightBoxComponent
