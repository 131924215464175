import {GET_ALL_FEATURES, LOAD_FEATURES_LOADING, LOAD_FEATURES_ERROR } from '../constants/features-contants';


const initialState = {
    features: null,
    isLoading: true,
    error: null,
}

export default function onFeatureActionDone(state = initialState, action) {
    switch (action.type) {
        case LOAD_FEATURES_LOADING:
            return {
                ...state,
                isLoading: true,
                features: null

            }
        case GET_ALL_FEATURES:
            return {
                ...state,
                isLoading: false,
                features:action.payload 
            }

        case LOAD_FEATURES_ERROR:
            return {
                ...state,
                isLoading: false,
                features: null,
                error: action.payload
            }
        default:
            return state;
    }

}

