import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Card } from 'react-bootstrap'
import './ContactUsForm.css'
import * as yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';


toast.configure()

const ContactUsForm = (props) => {

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            message: ''
        },
        validationSchema: yup.object({
            fullName: yup.string()
                .label('Full Name')
                .matches(
                    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                        'Full Name can only contain Latin letters.'
                    )
                .required(),
            email: yup.string()
                .label("Email")
                .email()
                .required(),
            message: yup.string()
                .label('Message')
                .min(15, 'Message should be greater than 15 characters.')
                .required()
        }),
        onSubmit: values => {
            setIsButtonLoading(true);
            props.onSubmitQueryHandler(values)
                .then((data) => {
                    setIsButtonLoading(false);
                    toast.success("Thank you , Your query has been submit successfully")
                    formik.resetForm()
                })
                .catch((e) => {
                    setIsButtonLoading(false);
                    toast.error(`${e}`)
                });
        }
    });

    return (
        <div className='container-card'>
            <div className='div-contact-box'>
                <form onSubmit={formik.handleSubmit} className='container-form'>
                    <label htmlFor="firstName" className='field-title'>Full Name</label>
                    <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        placeholder='Your Name'
                        onChange={formik.handleChange}
                        value={formik.values.fullName}
                        className="input-field"
                    />
                    {formik.errors.fullName ? <div className='div-error'>{formik.errors.fullName}</div> : null}
                    <label htmlFor="email" className='field-title1'>Email Address</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder='Email'
                        className="input-field"
                        onChange={formik.handleChange}
                        value={formik.values.email}/>
                    {formik.errors.email ? <div className='div-error'>{formik.errors.email}</div> : null}
                    <label htmlFor="Message" className='field-title1'>Message</label>
                    <textarea
                        id="message"
                        name="message"
                        type="text"
                        placeholder="Text message here!"
                        className="input-field-message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                    />
                    {formik.errors.message ? <div className='div-error'>{formik.errors.message}</div> : null}
                    <div className='div_button'>

                        <Button type="submit" variant="primary" className='contact-button'>
                            <div style={{ height: 25, width: 25, textAlign: 'center' , alignItems:'center'}}>
                                {isButtonLoading &&
                                    <Spinner
                                        className="spinner-border spinner-border-sm"
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border" />
                                }
                            </div>
                            <div className='submit_button_title'> Send Message</div>
                        </Button>


                    </div>
                    <ToastContainer
                        className="toaster-container"
                        position="bottom-right"
                        autoClose={500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
                </form>
            </div>
        </div>
    )
}

export default ContactUsForm
