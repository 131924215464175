import React,{useEffect, useState}from 'react';
import { MDBDataTable } from 'mdbreact';
import './SmsList.css'
import RefreshButton from '../../banner/RefreshButton';
import parsePhoneNumber from 'libphonenumber-js'
import SmsListShimmer from './shimmer/SmsListShimmer';
import EmptySms from './emptysms/EmptySms';
import { Link } from 'react-router-dom'
import 'bootstrap-icons/font/bootstrap-icons.css'
import useScreenType from '../../../hooks/UseScreenType';
import {channel}  from '../../../eventbus/Pusher'


const SmsList = (props) => {

  const {isMobile,isTablet} = useScreenType() 

  const onRefreshClicked = () => {
    props.onRefreshClicked(props.number)
  }

  const listToChannel =()=>{
    channel.bind('event-received', function(data) { 
      console.log("event-received-");
      props.onNewSmsReceive(props.number)
    });
  }

  useEffect(() => {
    listToChannel();
    props.onGetSmsList(props.number)
    return ()=>{
      channel.unbind('event-received');
    }
  }, []);

  const data = {
    columns: [
      {
        label: 'Timestamp',
        field: 'timestamp',
        sort: 'asc',
        minWidth: 100,
      },
      {
        label: 'Number',
        field: 'number',
        sort: 'asc',
        minWidth: 100,
      },
      {
        label: 'Message',
        field: 'text',
        sort: 'asc',
        minWidth: 400
      }
    ],
    rows: props.data.smsReducer.data
  };


  return (
    <div className='container_smslist'>
      <div className='div-home-privacy'>
        <div className='privacy-policy-home'>
          <Link to={"/"} style={{
            padding: "0px 0px",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 16,
            color: "#6E6681",
          }}> Home</Link>
          <div style={{margin:"0px 3px"}}>
              &#62;
            </div>
        </div>
        <div style={{
          padding: "0px 0px",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 16,
          color: "#6E6681",
        }}>{parsePhoneNumber(props.number).formatInternational()}</div>
      </div>

    {props.data.smsReducer.isLoading && 
        <SmsListShimmer />
       }
    {props.data.smsReducer.data.length === 0 && !props.data.smsReducer.isLoading && <EmptySms onRefreshClicked={onRefreshClicked} />}
      {props.data.smsReducer.error && !props.data.smsReducer.isLoading && <p>{props.data.smsReducer.error}</p>}
      {props.data.smsReducer.data.length > 0 &&
        <div className='container_tabel'>
          <div className={!(isMobile||isTablet)?'container-title':'container-title1'}>
            <div className='container-title-text-start'>Received SMS: {props.data.smsReducer.data.length}</div>
            <div className='container-title-text-middle'>{ }</div>
            <div className='container-button'><RefreshButton onClicked={onRefreshClicked} /></div>
          </div>
          <div className={!isMobile?'container-datatabel':'container-datatabel1'}>
            <MDBDataTable
              striped
              hover
              borderless
              sortable={false}
              small
              responsive ={true}
              entrieslabel="Show Entries"
              displayEntries
              entriesOptions={[10, 20, 50, 100]}
              data={data}
              noBottomColumns
              searchLabel='Search...'
              noRecordsFoundLabel='No Records found.'
              info={false}
            />
          </div>
        </div>} 
    </div>
  );
}

export default SmsList;