import React from 'react'
import './ComponentHeader.css'

const ComponentHeader = (props) => {
  return (
    <div className='div-component-header'>
      {props.subtitle}
    </div>
  )
}

export default ComponentHeader
