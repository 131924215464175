import http from "./http-common";

class ApiEndPointService {

  getAllNumbers(country){;
    return http.get('/telnyx/getMyNumbers',{ params: {country:country} });
  }

  getAvaliableCountry(){
    return http.get('/country/getCounties');
  }

  getCountryDetails(alphaCode){
    return http.get('/country/getCountryDetails',{ params: { code: alphaCode}});
  }


  getSmsListByNumber(number){
    return http.get('/telnyx/smslistByNumber/',{ params: { number: number} });
  }

  getAllFaqs(){
    return http.get('/api/getFaqs');
  }
  

  postQuery(value){
    return http.post('/api/submitQuery' , value);
  }

  getAllFeatures(){
    return http.get('/features/getFeatures');

  }

  getTestimonials(){
    return http.get('/testimonials/getTestimonials');

  }

}
export default new ApiEndPointService();