import React from 'react'
import { Button } from 'react-bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './refreshButton.css'

function RefreshButton({ onClicked }) {

  return (
    <div>
      <Button onClick={() => {
        onClicked()
      }}
        className='btn'
        variant="light"
        size='md'
        style={{
          fontFamily: 'Manrope',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: "16px",
          color: "#6E6681",
          alignItems: "center",
          background: "#FFFFFF",
          border: "1px solid #E7E6EB",
          borderRadius: "8px",
          width: "117px",
          height: "36px",
          margin: '0 0 0 auto',
        }}>
        <i
          className="bi bi-arrow-clockwise icon">
        </i>
        Refresh
      </Button>
    </div >
  )
}

export default RefreshButton