import {connect}  from 'react-redux';
import  CountryList from '../components/dashboard/numberlisting/country/CountryList';
import {onCountrySelected,getCountyList}  from '../services/actions/country-action';


const mapStateToProps = state =>({
    data : state
});

const mapDispatchToProps = dispatch => ({
    onGetCountyList:()=> dispatch(getCountyList()),
   onCountrySelectedHandler:data => dispatch(onCountrySelected(data))
});


export default connect(mapStateToProps,mapDispatchToProps)(CountryList);

