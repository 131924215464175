
import React from 'react'
import './Features.css'
import ComponentTitle from '../common/componenttitle/ComponentTitle'
import ComponentHeader from '../common/componentheader/ComponentHeader'
import ComponentDescription from '../common/component-desc/ComponentDescription'
import FeaturesContainer from '../../containers/features-container'


const Features = () => {
  
  return (
    <div className='div-features'>
    <div className='div-features-inner'>
            <ComponentTitle tilte={'Features'} />
            <ComponentHeader subtitle={"100+ Free Numbers, one platform."} />
            <ComponentDescription description ={"GetFreeSMSOnline offers a wide range of disposable phone numbers with awesome features. Receive OTPs, SMS and messages instantly online from anywhere in the world."}/>
            <FeaturesContainer/>
        </div>
</div>
  )
}

export default Features
