
import Pusher from 'pusher-js'; 
require('dotenv').config()

Pusher.logToConsole = false;
var pusher = new Pusher('9f0ec49f1631adfb94e6',{
    cluster: 'us2'
});

export const  channel = pusher.subscribe('getfreesmsonline')




  
