import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import './BoxComponent.css'
import Avatar from '../../../common/avater/Avatar'
import avatarImage from '../../../../assests/testimonials/avatar.png'
import quate from '../../../../assests/testimonials/fa_quote-left.svg'
import useScreenType from "../../../../hooks/UseScreenType";

function BoxComponent(props) {

  const {isMobile,isTablet} = useScreenType() 

  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });
  return (
    <animated.div
      className={!(isMobile||isTablet)?'card1':'card2'}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}>
      {!(isMobile||isTablet) && <div className='div_opinion-line-top'/>}
      <div className='div-opinion-container'>
        <div className='div-opinion-container-left'>
          <img src={quate} alt="quate" style={{
            width: "27px", height: '23px',
            verticalAlign: 'middle',
            borderRadius: "50%"
          }} />
        </div>
        <div className='div-opinion-container-right'>
            <div className={!(isMobile || isTablet)?'div-text-opinion':'div-text-opinion2'}>
              {props.testimonial != null ? props.testimonial.testimonial : ''}
            </div>
          <div className="div-avatar">
          <Avatar image={(props.testimonial !== null && props.testimonial.imageurl !== null) ? props.testimonial.imageurl : avatarImage} height={"44px"} width={"44px"} borderRadius={"50%"} />
          </div>
            <div className='div-text-user-details'>
              <div className='div-text-name'>
                {props.testimonial != null ? props.testimonial.username : ''}//
              </div>
              <div className='div-text-company'>
                {props.testimonial != null ? props.testimonial.company : ''}
              </div>
              <div className='div-text-designation'>
                , {props.testimonial != null ? props.testimonial.designation : ''}
              </div>
            </div>
          </div>
      </div>
    </animated.div>
  );
}

export default BoxComponent;
