import {VIEW_RECEIVED_MESSAGES}  from  '../constants/dashboard-const';

const initialState = {
    number:null,
    numbers: [],
    loading: false,
    error: null,
}

export default function onNumberTap(state = initialState ,action){
    switch(action.type){
        case VIEW_RECEIVED_MESSAGES:    
        return{
            ...state ,
            number: action.number
        };
        default:
        return state;
    }
}