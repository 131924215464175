import {GET_COUNTRY, ON_COUNTRY_ITEM_TAP, GET_ALL_CONTRIES, LOAD_COUNTRIES_LOADING, LOAD_COUNTRY_ERROR } from '../constants/countries-constant';


const initialState = {
    countries: [],
    isLoading: true,
    error: null,
    country:{ 
        id : "7or483XgMknAHwSksADtpr", 
        dial_code : "+1", 
        alpha_two_code : "CA", 
        name : "Canada", 
        flagUrl : "https://krispcall-prod.sgp1.digitaloceanspaces.com/storage/flags/ca.png"
    }
}


export default function onCountryReducerLoad(state = initialState, action) {

    switch (action.type) {
        case GET_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case ON_COUNTRY_ITEM_TAP:
            return {
                ...state,
                country: action.country
            };

        case LOAD_COUNTRIES_LOADING:
            return {
                ...state,
                isLoading: true,
                countries: []

            }
        case GET_ALL_CONTRIES:        
            return {
                ...state,
                isLoading: false,
                countries: action.payload
            }

        case LOAD_COUNTRY_ERROR:
            return {
                ...state,
                isLoading: false,
                countries: [],
                error: action.payload
            }
        default:
            return state;
    }

}



