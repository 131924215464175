import { GET_ALL_FAQS ,LOAD_FAQ_LOADING, LOAD_FAQ_ERROR} from '../constants/faq-constants';
import ApiEndPointService from "../network/api-service";



export const getFaqs=()=> async(dispatch)=>{    
    dispatch({ type: LOAD_FAQ_LOADING});
    try {
        
      const res = await ApiEndPointService.getAllFaqs();
      dispatch({
        type: GET_ALL_FAQS,
        payload: res.data.faqs
      });
    } catch (err) {
        console.warn("Errror",err);
        dispatch({
            type: LOAD_FAQ_ERROR,
            payload: err,
          });

    }
}

