import {ON_NUMBER_ITEM_TAP,GET_ALL_NUMBERS,LOAD_NUMBER_LOADING,REFRESH_ALL_NUMBERS, LOAD_NUMBER_ERROR}  from  '../constants/number-constants';


// https://www.bezkoder.com/react-redux-crud-example/

const initialState = {
    numbers: [],
    isLoading: true,
    onRefresh:false,
    error: null,
}


export default function onNumberReducerLoad(state = initialState ,action){

    switch(action.type){
        case ON_NUMBER_ITEM_TAP:
        return{
            ...state ,
            onRefresh:false,
            number: action.number
        };

        case LOAD_NUMBER_LOADING:
        return{
            ...state,
            isLoading: true,
            onRefresh:false,
            numbers:[]     
        
        }

        case GET_ALL_NUMBERS:
            return{
                ...state,
                isLoading:false,
                onRefresh:false,
                numbers:action.payload
            }

        case REFRESH_ALL_NUMBERS:
                return{
                    ...state,
                    isLoading:false,
                    onRefresh:!state.onRefresh,
                    numbers:action.payload 
                }
        case LOAD_NUMBER_ERROR:
                return{
                    ...state,
                    isLoading: false,
                    onRefresh:false,
                    numbers:action.payload
            }

        default:

        return state;

    }
    
}