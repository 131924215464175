import React from 'react'
import './ComponentTitle.css'

const ComponentTitle = (props) => {
  return (
    <div className='div-component-title'>
        <div className='component-title'>
          {props.tilte}
        </div>
    </div>
  )
}

export default ComponentTitle
