

export const timeAgo = (dateString , showAgo = true) =>{
    // returns format eg: 1 mnth ago, 1h ago
    const seconds = Math.floor((new Date().valueOf() - new Date(dateString).valueOf()) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return `${Math.floor(interval)}y ${showAgo ? 'ago' : ''}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)}mth ${showAgo ? 'ago' : ''}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)}d ${showAgo ? 'ago' : ''}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)}h ${showAgo ? 'ago' : ''}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)}m ${showAgo ? 'ago' : ''}`;
    }
    return `a few seconds ${showAgo ? 'ago' : ''}`;
  }



  