
import React from 'react'
import Avatar from '../../../common/avater/Avatar'
import './UserTile.css'
import useScreenType from '../../../../hooks/UseScreenType'


const UserTile = (props) => {


  const {isMobile,isTablet,isLaptop} = useScreenType() 

  
  return (
    <div className='div-user-tile' onClick={() => {
      props.onSelected(props.index, props.testimonial);
    }}>
      <div className={`div-user-tile-inner  ${props.selected && 'div-user-tile-inner-selected'}`}>
        <Avatar image={props.testimonial.imageurl} />
        <div className='div-user-list-right-component'>
          <div className={`div-user-name ${props.selected && 'div-user-name-selected'}`}>
            {props.testimonial.username}
          </div>
          <div className={`div-user-company  ${props.selected && 'div-user-company-selected'}`}>
            {props.testimonial.company}
          </div>
        </div>

      </div>
      {props.selected && <div className={!(isMobile||isTablet||isLaptop)?'div-indicator':'div-indicator1'}/>}
    </div>
  )
}

export default UserTile
