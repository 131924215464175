import {GET_All_SMS_BY_NUMBERS ,LOAD_SMS_LOADING, LOAD_SMS_ERROR,REFRESH_SMS_BY_NUMBERS} from '../constants/smslist-contants';
import ApiEndPointService from "../network/api-service";



export const getReceivedSmsByNumber=(number)=> async(dispatch)=>{    
    dispatch({ type: LOAD_SMS_LOADING});
    try {
        
      const res = await ApiEndPointService.getSmsListByNumber(number);
      dispatch({
        type: GET_All_SMS_BY_NUMBERS,
        payload: res.data.data,
      });
    } catch (err) {
        console.warn("Errror",err);
        dispatch({
            type: LOAD_SMS_ERROR,
            payload: err,
          });

    }

}



export const refreshSmsList=(number)=> async(dispatch)=>{ 

  try {
    const res = await ApiEndPointService.getSmsListByNumber(number);
    dispatch({
      type: REFRESH_SMS_BY_NUMBERS,
      payload: res.data.data,
    });
  } catch (err) {
      console.warn("Errror",err);
      dispatch({
          type: LOAD_SMS_ERROR,
          payload: err,
        });

  }

}



