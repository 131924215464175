import React, { useEffect } from 'react'

const FlagComponent = (props) => {


  const country = props.data.countryListReducer.country;

  useEffect(() => {
    props.onGetCountryDetails(props.number)
  }, [])

  return (
    <div>
      {country && <img className="row no-gutters" src={country.flagUrl} alt="" style={{ height: props.height, width: props.width, objectFit: props.objectFit, borderRadius: "2px" }} />}
    </div>
  )
}
export default FlagComponent
