import React ,{ useEffect } from 'react'
import Footer from '../../footer/Footer'
import Header from '../../header/Header'
import './ContactUs.css'
import PrivacyContactTopBar from '../topbar/PrivacyContactTopBar'
import { Link } from 'react-router-dom'
import {CONTACT_US,CONTACT_DESC}  from '../constants'
import ContactUsFormContainer from '../../../containers/contactus-container'

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <div>
      <Header showFaq={false} showPrivacy ={false} isContactSelected={true} isHomeSelected={false}/>
      <PrivacyContactTopBar title = {CONTACT_US}  desc ={CONTACT_DESC} />
      <div className='contact-container'>
        <div className='container-home-contact'>
          <div className='contactus-home'>
            <Link to={"/"} style={{
              padding: "0px 0px", 
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 16,
              color: "#6E6681",
            }}> Home</Link>
            <div style={{margin:"0px 3px"}}>
              &#62;
            </div>
          </div>
          <div className='text-contactus'>{CONTACT_US}</div>
        </div>
        <div style={{textAlign:'center', alignItems:'center',display:'flex',flexDirection:'column'}}> 
        <div className='text-contact-top'>
          Need help or just have a general question? Email us at "info@getfreesmsonline.com" we'll get back to you as soon as possible.
        </div>
        <ContactUsFormContainer/>
        </div>
        <div className='contact-bottom'>
          <div className='contact-bottom-text'>
          If the answers to common questions and a detailed description on the home page about the Temp Mail service do not assist you let us know. Only from your feedback can we provide solutions for other users.<br/>
         If required we will consult with you at any time of the day. We will help to efficiently manage services on offer, to solve problems you may experience in receiving emails in the unlikely event they occur.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default ContactUs
