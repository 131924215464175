import {connect}  from 'react-redux';
import  LeftBoxComponent  from '../components/receivesms/topbar/leftbox/LeftBoxComponent'
import {onCountrySelected}  from '../services/actions/country-action';


const mapStateToProps = state =>({
    data : state
});

const mapDispatchToProps = dispatch => ({
   onCountrySelectedHandler:data => dispatch(onCountrySelected(data))
});


export default connect(mapStateToProps,mapDispatchToProps)(LeftBoxComponent);

