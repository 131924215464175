import React from 'react'
import { Card } from 'react-bootstrap';
import './NumberShimmer.css'
import Shimmer from "react-shimmer-effect";


const NumberShimmer = () => {
    return (
        <div>
            <Card className='card-shimmer-container'>
                    <div className="container-shimmer">
                        <Shimmer>
                        <div className= "container-header" />
                        </Shimmer>
                        <div className='div-flag'>
                        <Shimmer>
                        <div className="container-flag" />
                        </Shimmer>
                        </div>
                        <Shimmer>
                        <div className= "container-shimmer-button" />
                        </Shimmer>
                    </div>
            </Card>
        </div>
    );
}

export default NumberShimmer
