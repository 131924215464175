import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router}  from 'react-router-dom';
import { Provider } from 'react-redux'
import reducer from './services/reducers/index'
import { createStore , applyMiddleware} from 'redux';
import thunk from 'redux-thunk'


const store = createStore(reducer,applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
  <React.Fragment>
   <Router>
     <App/>
    </Router>
    </React.Fragment>
    </Provider>,
  document.getElementById('root')
);
reportWebVitals();
