import React from 'react'
import './Banner.css'
// import banner from '../../assests/banner/banner.svg'
import banner from '../../assests/banner/banner_background.svg'
import VisitButton from './VisitButton'

const Banner = () => {

  const onClicked = () => { }
  return (
    <div style={{
      position: 'relative',
      backgroundColor: "#B3B3FC",
      backgroundImage: `url(${banner})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '300px',
      backgroundSize: 'cover'
    }}>
      <div className='banner-container'>
        <div className='banner-container-inner'>
          <div className='banner-container  frame2'>
            <div className='banner-container-title'>
              <p>Annual Plan <span className="div-spam">10% OFF</span> for Permanent Phone Number</p>
            </div>
            <div className='banner-container desc'>
              <p>Our website allows developers to receive SMS online for testing. We provide disposable/temporary phone numbers so you can test 2FA using SMS.</p>
            </div>
            <div className='div-visit-button'>
            <VisitButton style={{
              margin: "0px 0px !important"

            }} onClicked={onClicked} />
            </div>
     
          </div>
        </div>
      </div>

    </div>
  )
}
export default Banner

