import React from 'react'
import Table from 'react-bootstrap/Table'
import Shimmer from "react-shimmer-effect";
import './SmslistShimmer.css'
import useScreenType from '../../../../hooks/UseScreenType';


const SmsListShimmer = () => {


  const {isMobile,isTablet} = useScreenType() 

  const array = ['', '', '', '', '', '', '', ''];

  return (
    <>
      <div className='container_shimmer'>
        <div className={!(isMobile || isTablet)?'container-shimmer_title':'container-shimmer_title1'}>
          <div className='shimmer-title-text-start'>
            <Shimmer>
              <div className='div-title-shimmer1'></div>
            </Shimmer>
          </div>

          <div className='shimmer-title-text-mid'>
            <div className='div-title-shimmer'></div>
          </div>
          <div className='shimmer-title-text-end'>
            <Shimmer>
              <div className='div-title-end'></div>
            </Shimmer>
          </div>
        </div>
        <div className= {!(isMobile||isTablet)?'container_shimmer_tabel':'container_shimmer_tabel1'} >
        <div className= {!(isMobile||isTablet)?'container-datatabel-shimmer':'container-datatabel-shimmer1'} >
        <div className= {!(isMobile||isTablet)?'container-shimmer_table_title':'container-shimmer_table_title1'} >
              <div className={!(isMobile||isTablet)?'shimmer-dropdown':'shimmer-dropdown1'}>
                <Shimmer>
                  <div className='div-dropdown-title'></div>
                </Shimmer>
                <Shimmer>
                  <div className='div-dropdown-shimmer'></div>
                </Shimmer>
              </div>
              <div className='shimmer-title-text'>
                <div className='div-title-shimmer'></div>
              </div>
              <div className='shimmer-title-search'>
                <Shimmer>
                  <div className={!(isMobile||isTablet)?'div-title-search':'div-title-search1'}></div>
                </Shimmer>
              </div>
            </div>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>
                    <div className='shimmer_table_title'>
                      <Shimmer>
                        <div className='div-title-shimmer'></div>
                      </Shimmer>
                    </div>
                  </th>
                  {/* <th>
                    <div className='shimmer_table_title'>
                      <Shimmer>
                        <div className='div-title-shimmer'></div>
                      </Shimmer>
                    </div>
                  </th>
                  <th>
                    <div className='shimmer_table_title-end'>
                      <Shimmer>
                        <div className='div-text-end'></div>
                      </Shimmer>
                    </div>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {array.map((_, index) => (
                  <tr key ={index}>
                    <td>
                      <div className='shimmer_table_row' >
                        <Shimmer>
                          <div className='div-title-shimmer'></div>
                        </Shimmer>
                      </div>
                    </td>
                    {/* <td>
                      <div className='shimmer_table_row'>
                        <Shimmer>
                          <div className='div-title-shimmer'></div>
                        </Shimmer>
                      </div>
                    </td>
                    <td>
                      <div className='shimmer_table_title'>
                        <Shimmer>
                          <div className='shimmer_table_row'></div>
                        </Shimmer>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmsListShimmer


