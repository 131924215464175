import {GET_COUNTRY,ON_COUNTRY_ITEM_TAP , GET_ALL_CONTRIES ,LOAD_COUNTRIES_LOADING, LOAD_COUNTRY_ERROR} from '../constants/countries-constant';
import ApiEndPointService from "../network/api-service";


export const onCountrySelected =(country)=>{
    return {
        type: ON_COUNTRY_ITEM_TAP,     
        country : country,
    };
}

export const getCountyList=()=> async(dispatch)=>{   
  
    dispatch({ type: LOAD_COUNTRIES_LOADING});

    try {
      const res = await ApiEndPointService.getAvaliableCountry();
      if(res.data.countries !== undefined){
        dispatch({
          type: GET_ALL_CONTRIES,
          payload: res.data.countries,
        });
      }
    } catch (err) {

        dispatch({
            type: LOAD_COUNTRY_ERROR,
            payload: err,
          });

    }
}

export const getCountryDetails=(alphaCode)=> async(dispatch)=>{    

  dispatch({ type: LOAD_COUNTRIES_LOADING});

  try {

    const res = await ApiEndPointService.getCountryDetails(alphaCode);

    dispatch({
      type: GET_COUNTRY,
      payload: res.data.country,
    });

  } catch (err) {
      dispatch({
          type: LOAD_COUNTRY_ERROR,
          payload: err,
        });
  }
}

