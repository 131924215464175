
export const  ON_NUMBER_ITEM_TAP = "ON_NUMBER_ITEM_TAP";
export const GET_ALL_NUMBERS= 'GET_ALL_NUMBERS';
export const REFRESH_ALL_NUMBERS= 'REFRESH_ALL_NUMBERS';
export const LOAD_NUMBER_LOADING= 'LOAD_NUMBER_LOADING';
export const LOAD_NUMBER_ERROR= 'LOAD_NUMBER_ERROR';




