import React from 'react'
import './PrivacyContactTopBar.css'

const PrivacyContactTopBar = ({title, desc}) => {
    return (
            <div className='privacy-top-bar'>
                <div className='text-wrapper2'>
                    <div className='text-header2'>
                       {title}
                    </div>
                    <div className='text-footer2'>
                        <div className='text-footer2-inner'>
                        {desc}
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default PrivacyContactTopBar
