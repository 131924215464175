import React from 'react'
import './FeatureShimmer.css'
import Shimmer from "react-shimmer-effect";
import { Row, Col } from "react-bootstrap";
import useScreenType from '../../../../hooks/UseScreenType';

const FeatureShimmer = () => {

    const {isMobile,isTablet} = useScreenType() 

    const features = ['','','','','','','','',''];

    return (

     <Row md={6} className="div-shimmer-row">
            {features.map((value, k) => (
                    <Col className="col-md" key={k} xs={12} md={3} lg={4} style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0 }}>

                    <div className='div-feature-shimmer'>
                    {!(isMobile||isTablet)&& <Shimmer>
                            <div className='div-feature-shimmer-indicator'>
                            </div>
                        </Shimmer>}
                        <div className='div-feature-shimmer-content-container'>
                            <Shimmer>
                                <div className='div-feature-shimmer-title'>
                                </div>
                            </Shimmer>
                                <div className='div-feature-shimmer-description'>
                                    <Shimmer>
                                    <div className='div-feature-shimmer-description-tile'/>
                                    </Shimmer>
                                    <Shimmer>
                                    <div className='div-feature-shimmer-description-tile'/>
                                    </Shimmer>
                                    <Shimmer>
                                    <div className='div-feature-shimmer-description-tile'/>
                                    </Shimmer>
                                </div>
                        </div>

                    </div>
                </Col>
            ))}
        </Row>

     
    )
}
export default FeatureShimmer