import React, { Component } from 'react';
import { Routes ,Route } from 'react-router-dom';
import PrivacyPolicy from './components/privacycontact/privacy/PrivacyPolicy'
import ContactUs from './components/privacycontact/contact/ContactUs'
import Home from './components/Home';
import ReceiveSms from './components/receivesms/ReceiveSms';
import './eventbus/Pusher';



class App extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/privacy" element={<PrivacyPolicy/>} />
          <Route exact path="/contactus" element={<ContactUs/>} />
          <Route exact path="*" element ={<Home/>} />
          <Route exact path="/messgeList/:number" element ={<ReceiveSms/>} />
        </Routes>
      </div>
    );
  }
}

export default App;
