import React from 'react'
import useScreenType from '../../hooks/UseScreenType'
import BrowserFooter from './browser/BrowserFooter'
import MobileFooter from './mobile/MobileFooter'


const Footer = () => {

    const {isMobile,isTablet,isLaptop} = useScreenType() 

    
  return (
    <div>
        {!(isMobile  || isTablet)? <BrowserFooter/>:<MobileFooter/>}
    </div>
  )
}

export default Footer
