import React from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './Header.css'
import logo from '../../assests/logo.png'
import eventBus from '../../eventbus/EventBus';
import useScreenType from '../../hooks/UseScreenType';



const Header = (props) => {

    const {isMobile,isTablet,isLaptop} = useScreenType() 

    const navigate = useNavigate();

    const onClick = (path) => {
        navigate(path);
    }




    const onFaqClicked = () => {
        eventBus.dispatch("onFaqClicked", {})
    }


    return (
        <div className="header-container">
            <div className='header-container-inner'>
                <div className="navbar-left" onClick={() => {
                    onClick('/');
                }}><div className='logo-name'>
                        <img src={logo} alt='logo' style={{ height: '33px', width: '33px', objectFit: 'cover', marginRight: '5px' }} />
                        <div>
                        GetFreeSMSonline
                        </div>
                    </div></div>
                <div className="navbar-middle" >
                    {props.showFaq && <Link to="/" 
                        onClick={() => {
                            onFaqClicked()
                        }}>FAQ</Link>}
                    {props.showPrivacy && <Link to="/privacy" className='navbar-middle-row-privacy'>Privacy Policy</Link>}
                </div>
                
                <div className='nav-bar_end'>
                    {!(isMobile||isTablet)?<div onClick={() => {
                        onClick("/");
                    }}
                        style={{
                            color: props.isHomeSelected ? '#2D66F7' : '#1A294F',
                            marginRight: '4px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}>Home</div>:<></>}
                    <div onClick={() => {
                        onClick("/contactus");
                    }}
                        style={{
                            color: props.isContactSelected ? '#2D66F7' : '#1A294F',
                            marginRight: '4px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}>Contact</div>
                </div>
            </div>
        </div>

    );

}

export default Header
