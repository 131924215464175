import React from 'react'
import './FeatureTile.css'
import useScreenType from '../../../../hooks/UseScreenType'

const FeatureTile = (props) => {

    const {isMobile,isTablet} = useScreenType() 

    return (
        <div className={!(isMobile||isTablet)?'div-feature-tile':'div-feature-tile1'}>
           {!(isMobile||isTablet)&&<div className='div-feature-tile-indicator'>
                <div className='div-indicator-text'>
                    {props.index+1}
                </div>
            </div>} 
            <div className='div-feature-tile-content-container'>
                <div className='div-feature-tile-title'>
                    {props.feature.heading}
                </div>
                <div className='div-feature-tile-description'>
                {props.feature.detail}
                </div>

            </div>

        </div>
    )
}
export default FeatureTile
