import {connect}  from 'react-redux';
import  Dashboard from '../components/dashboard/Dashboard';
import {onNumberTap}  from '../services/actions/dahboard-action';

const mapStateToProps = state =>({

});

const mapDispatchToProps = dispatch =>({
   onNumberTapHandler:data => dispatch(onNumberTap(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);

