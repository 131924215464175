import { connect } from 'react-redux';
import SmsList from '../components/receivesms/smslist/SmsList';
import { getReceivedSmsByNumber,refreshSmsList} from '../services/actions/smslist-action';


const mapStateToProps = state => ({
    data: state
});

const mapDispatchToProps = dispatch => ({
    onGetSmsList: number => dispatch(getReceivedSmsByNumber(number)),
    onRefreshClicked:number => dispatch(getReceivedSmsByNumber(number)),
    onNewSmsReceive: number => dispatch(refreshSmsList(number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmsList);

