import React from 'react'
import NumberListContainer from '../../../containers/numberlist-container';
import CountryListContainer from '../../../containers/country-containers';
import DashBoardTopBar from '../topbar/DashboardTopBar';
import './NumberListing.css'
import useScreenType from '../../../hooks/UseScreenType';

const NumberListing = () => {

  const {isMobile,isTablet} = useScreenType() 


  return (
    <div>
      <DashBoardTopBar isBrowserView ={isMobile?false:true}/>
      <div className='number-list'>
        <CountryListContainer />
        <div style={{maxWidth:'1276px' , padding:'20px 0px'}}>
       <hr  className={!(isMobile||isTablet)?'div-hr-line-browser':'div-hr-line-mobile'}/>
       </div>
        <NumberListContainer />
        <div style={{maxWidth:'1276px' , padding:'0px 0px'}}>
        <hr  className={!(isMobile||isTablet)?'div-hr-line-browser':'div-hr-line-mobile'}/>
        </div>
      </div>
    </div>
  )
}
export default NumberListing
