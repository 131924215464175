import { useMediaQuery } from 'react-responsive';

function useScreenType() {
    
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 991 }); // >= 767  (screen: 768)

  const isLaptop = useMediaQuery({ minWidth: 991, maxWidth: 1199 }); // >= 991 (screen: 992)

  const isDesktop = useMediaQuery({ minWidth: 1199, maxWidth: 1919 }); // >= 1199 (screen: 1200)

  const isBigScreen = useMediaQuery({ minWidth: 1920 });
  return { isMobile, isTablet, isLaptop, isDesktop, isBigScreen };
}

export default useScreenType;