import React from 'react'
import { Button } from 'react-bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './Button.css'

function VisitButton({ onClicked }) {
  return (
    <Button onClick={() => {
      onClicked()
    }}
      className ='visit-Button'
      varient="primary"
      size="md">
      Go to Website
      <i className="bi bi-arrow-right" style={{ paddingLeft: '1rem' }}></i>
    </Button>
  )
}

export default VisitButton