import React from 'react'
import { Link } from 'react-router-dom'
import './BrowserFooter.css'
import logo from '../../../assests/union.svg'



const BrowserFooter = () => {

  

  return (
    <footer>
      <div className='container-footer'>
        <div className='footer-left'>
          <div className='logo-name'>
            <img src={logo} alt='logo' className='footer-logo' />
            <div className='div-title-left'>
              GetFreeSMSonline
            </div>
          </div>
          <div className='copyright'>
            All Rights Reserved, 2022
          </div>
        </div>
        <div className='footer-right'>
          <Link className="footer-policy" to={"/privacy"} >Privacy Policy </Link> | <Link className="footer-contactus" to={"/contactus"}> Contact Us</Link>
          <div className='div-title-right'>
            GetFreeSmsOnline
          </div>
        </div>
      </div>
    </footer>)
}

export default BrowserFooter
