import {connect}  from 'react-redux';
import  Features  from '../components/features/featurelist/Featurelist'
import {getFeatures}  from '../services/actions/features-action';


const mapStateToProps = state =>({
    data : state
});

const mapDispatchToProps = dispatch => ({
    onGetFeaturesList:()=>{
        dispatch(getFeatures())
    },
});

export default connect(mapStateToProps,mapDispatchToProps)(Features);