import React from 'react'
import './Process.css'
import ComponentDescription from '../common/component-desc/ComponentDescription'
import p1 from '../../assests/process/process1.svg'
import p2 from '../../assests/process/process2.svg'
import p3 from '../../assests/process/process3.svg'
import useScreenType from '../../hooks/UseScreenType'


const Porcess = () => {



    const { isMobile, isTablet , isLaptop} = useScreenType()


    return (
        <div className='div-process'>
            <div className='div-process-inner'>
                <div className='div-process-title'>
                    <div className='title'>
                        Process
                    </div>
                </div>
                <div className='div-process-header'>
                    How it works
                </div>
                <ComponentDescription description={"Step by step instructions on how to receive SMS, OTP, and messages on temporary phone numbers."} />
                <div className={!(isMobile || isTablet || isLaptop) ? 'div-process-steps-browser' : 'div-process-steps-mobile'}>
                    <img src={p1} alt="process1" className={!(isMobile || isTablet || isLaptop) ? 'div-process1-browser' : 'div-process1-mobile'} />
                    <img src={p2} alt="process2" className='div-process2' />
                    <img src={p3} alt="process3" className={!(isMobile || isTablet || isLaptop) ? 'div-process3-browser' : 'div-process3-mobile'} />
                </div>
            </div>
        </div>
    )
}

export default Porcess
