import React from 'react'
import Shimmer from "react-shimmer-effect";
import './AccordinShimmer.css'
import useScreenType from '../../../hooks/UseScreenType';


const AccordinShimmer = () => {
    const array = ['', '', '', ''];

    const {isMobile,isTablet,isLaptop} = useScreenType() 

    return (
        <div className='accordion-shimmer-container'>
            <div className="head-shimmer">
                <Shimmer>
                    <div className='header' />
                </Shimmer>
            </div>

          {!(isMobile || isTablet)?<div className="accordion-shimmer">
                <div>
                {array.map((item, i) => (
                    <div className="item-shimmer " key ={item+i}>
                        <Shimmer>
                            <div className="title-shimmer">
                            <div style={{width:'100%',minWidth:(isLaptop)?"510px":'510px'}}></div>
                            </div>
                        </Shimmer>
                    </div>
                ))}
                </div>
                <div>
                {array.map((item, i) => (
                    <div className="item-shimmer " key ={item+i}>
                        <Shimmer>
                            <div className="title-shimmer">
                                <div style={{width:'100%',minWidth:(isLaptop)?"430px":'510px'}}></div>
                                </div>
                        </Shimmer>
                    </div>
                ))}
                </div>
            
            </div>:
            <div className='div-shimmer-mobile'>
            {array.map((item, i) => (
                    <div className="item-shimmer" key ={item+i}>
                        <Shimmer>
                            <div className="title-shimmer-mobile" />
                        </Shimmer>
                    </div>
                ))}
                </div>}
        </div>
    )
}

export default AccordinShimmer
