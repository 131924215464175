

import React, { useEffect } from 'react'
import FeatureTile from './featuretile/FeatureTile'
import { Row, Col } from "react-bootstrap";
import './Featurelist.css'
import FeatureShimmer from './shimmer/FeatureShimmer'

const Featurelist = (props) => {

    const { features } = props.data.featuresReducer;

    useEffect(() => {
        props.onGetFeaturesList()
    }, [])


    return (
        <div className='div-feature-list'>

            {props.data.featuresReducer.isLoading && <div>
                <FeatureShimmer />
            </div>}
            {props.data.featuresReducer.features !== null && props.data.featuresReducer.features.length === 0 && !props.data.featuresReducer.isLoading && <p>{ }</p>}
            {props.data.featuresReducer.error && !props.data.featuresReducer.isLoading && <p>{props.data.featuresReducer.error}</p>}
            {features && <Row md={4} className="div-feature-row">
                {features.map((value, k) => (
                    <Col className="col-md" key={k} xs={12} md={3} lg={4} style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0 }}>
                        <FeatureTile key={k} feature={features[k]} index={k} />
                    </Col>
                ))}
            </Row>}

        </div>
    )
}

export default Featurelist
